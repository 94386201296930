import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { doc, getDoc } from "firebase/firestore";
import { db } from '../../firebaseConfig';
import { Button, Card, Row, Col } from 'react-bootstrap';
import { getAnalytics, logEvent } from "firebase/analytics";

const analytics = getAnalytics();

function logScreenView(screenName) {
  logEvent(analytics, "screen_view", {
    screen_name: screenName,
  });
}


function NonProfitDetailPage() {
  const { nonProfitId } = useParams();
  const [nonProfit, setNonProfit] = useState(null);
  const navigate = useNavigate();
  const locationState = useLocation();
  const fromCreateEventPage = locationState.state?.fromCreateEventPage || false;

//  console.log("詳細画面のlocation", location);
//  console.log("test3: ", locationState.state?.fromCreateEventPage || false);
    console.log("fromCreateEventPage:", fromCreateEventPage);

  console.log("詳細画面のlocationState", locationState);

  useEffect(() => {
    document.title = 'NonProfitDetailPage';
    logScreenView('NonProfitDetailPage');
  }, []);


  useEffect(() => {
    const fetchData = async () => {
      const docRef = doc(db, "nonprofits", nonProfitId);
      const docSnap = await getDoc(docRef);
      const nonProfitData = { ...docSnap.data(), id: docSnap.id };
      setNonProfit(nonProfitData);
    };

    fetchData();
    logEvent(analytics, "fetch_data", { screen_name: "NonProfitDetailPage" });
  }, [nonProfitId]);

  const handleBackToList = () => {
    navigate("/nonprofits");
  };
  

  const handleSelectNonProfit = () => {
    //5行追加‗0414_1500
    localStorage.setItem("selectedNonProfitId", nonProfit.id);
    const updatedFormData = {
      ...(locationState.state?.currentFormData || {}),
      organization: nonProfit.name, // ここで organization 情報を追加
    };
    navigate("/create-event", {
      replace: true,
      state: {
        ...locationState.state,
        currentFormData: updatedFormData,
//        ...locationState,
//        selectedNonProfitId: nonProfitId,
        selectedNonProfit: nonProfit,
      },
    });
  };
  

  

  if (!nonProfit) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1 className="mb-4">{nonProfit.name}</h1>
      <Row className="row-spacing">
        <Col sm={6} md={3}>
          <Card className="info-card">
            <Card.Header>ビジョン</Card.Header>
            <Card.Body>
              <Card.Text>{nonProfit.vision}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={6} md={3}>
          <Card className="info-card">
            <Card.Header>課題</Card.Header>
            <Card.Body>

            <Card.Text as="div">
              {nonProfit.challenge.split('\\n').map((paragraph, index) => (
              <React.Fragment key={index}>
                {paragraph}
                {index !== nonProfit.solution.split('\\n').length - 1 && <br />}
              </React.Fragment>
             ))}
            </Card.Text>

            </Card.Body>
          </Card>
        </Col>
        <Col sm={6} md={3}>
          <Card className="info-card">
            <Card.Header>解決策</Card.Header>
            <Card.Body>

            <Card.Text as="div">
              {nonProfit.solution.split('\\n').map((paragraph, index) => (
              <React.Fragment key={index}>
                {paragraph}
                {index !== nonProfit.solution.split('\\n').length - 1 && <br />}
              </React.Fragment>
             ))}
            </Card.Text>

            </Card.Body>
          </Card>
        </Col>
        <Col sm={6} md={3}>
          <Card className="info-card">
            <Card.Header>成果</Card.Header>
            <Card.Body>

            <Card.Text as="div">
  {nonProfit.results && nonProfit.results.split('\\n').map((paragraph, index) => (
    <React.Fragment key={index}>
      {paragraph}
      {index !== nonProfit.results.split('\\n').length - 1 && <br />}
    </React.Fragment>
  ))}
</Card.Text>

            </Card.Body>
          </Card>
        </Col>        
      </Row>
      
      <p className="website-link">
        <a
          href={nonProfit.website}
          target="_blank"
          rel="noopener noreferrer"
        >
        「{nonProfit.name}」のサイトを見る
        </a>
      </p>

      <p className="website-link">
        <a
          href={nonProfit.wishlist}
          target="_blank"
          rel="noopener noreferrer"
        >
        「欲しいものリスト」を見る
        </a>
      </p>


      <div className="button-container">
        {fromCreateEventPage && (
          <Button onClick={handleSelectNonProfit} className="custom-button-select">
            選択
          </Button>
        )}
        <Button variant="info" onClick={handleBackToList} className="custom-button-no ml-2">
          一覧に戻る
        </Button>
      </div>
    </div>
  );
}

export default NonProfitDetailPage;