import {Routes, Route } from "react-router-dom";
import Top from "../components/pages/Top";
import SignIn from "../components/pages/SignIn";
import CreateEvent from "../components/pages/CreateEvent";
import EventList from "../components/pages/EventList";
import EventDetail from "../components/pages/EventDetail";
import Profile from "../components/pages/Profile";
import UserRegistration from "../components/pages/UserRegistration";
import UserEdit from "../components/pages/UserEdit";
import RestaurantList from "../components/pages/RestaurantList";
import RestaurantDetail from "../components/pages/RestaurantDetail";
import NonProfitList from "../components/pages/NonProfitList";
import NonProfitDetail from "../components/pages/NonProfitDetail";
import HowItWorks from "../components/pages/HowItWorks";
import VisionPage from "../components/pages/VisionPage";
import ContactMe from "../components/pages/ContactMe";
import SurveyForm from "../components/pages/SurveyForm";
import Dashboard from "../components/pages/dashboard/Dashboard";
import PasswordPrompt from '../components/pages/PasswordPrompt';


export const Router = () => {
    return (
        <Routes>
            <Route exact path="/" element={<PasswordPrompt />} />
{/*            <Route exact path="/" element={<EventList />} /> */}
            <Route path="/top" element={<Top />} />    
            <Route path="/sign-in" element={<SignIn />} />
            <Route path="/register" element={<UserRegistration />} />

            <Route path="/create-event" element={<CreateEvent />} />
            <Route path="/event-list" element={<EventList />} />
            <Route path="/event/:id" element={<EventDetail />} />

            <Route path="/user-profile/:id" element={<Profile />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/user-edit" element={<UserEdit />} />

            <Route path="/restaurant-list" element={<RestaurantList />} />
            <Route path="/restaurant-detail/:id" element={<RestaurantDetail />} />

            <Route path="/nonprofits" element={<NonProfitList />} />
            <Route path="/nonprofits/:nonProfitId" element={<NonProfitDetail />} />

            <Route path="/how-it-works" element={<HowItWorks />} />
            <Route path="/vision-page" element={<VisionPage />} />
            <Route path="/contact-me" element={<ContactMe />} />
            <Route path="/survey" element={<SurveyForm />} />
        {/*    <Route path="/dashboard" element={<Dashboard />} />*/}
            <Route path="/dashboard/:restaurantDocId" element={<Dashboard />} />

        </Routes>
    )
}