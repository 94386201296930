import * as React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import { db } from '../../../firebaseConfig';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';

// Generate Event Data
function createData(id, date, name, organizer, capacity, amount) {
  return { id, date, name, organizer, capacity, amount };
}

function Orders() {
  const [events, setEvents] = useState([]);
  const { restaurantDocId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
//        console.log('Restaurant ID:', restaurantId);
        // 指定されたrestaurantIDに基づいてレストラン名を取得
        const restaurantDocRef = doc(db, 'restaurants', restaurantDocId);
//        console.log('restaurantDocRef:', restaurantDocRef);
        const restaurantDoc = await getDoc(restaurantDocRef);
        const restaurantData = restaurantDoc.data();
//        console.log('Restaurant data:', restaurantData);

        if (restaurantData && restaurantData.name) {
          const eventsData = [];
          const eventsQuerySnapshot = await getDocs(collection(db, 'events'));
          for (let i = 0; i < eventsQuerySnapshot.docs.length; i++) {
            const docData = eventsQuerySnapshot.docs[i].data();
//            console.log('Event data:', docData);
            if (docData.location === restaurantData.name) {
              const userDoc = await getDoc(doc(db, 'users', docData.createdBy));
              const organizerName = userDoc.data().displayName;
              eventsData.push(createData(eventsQuerySnapshot.docs[i].id, docData.dateTime, docData.eventName, organizerName, docData.capacity, docData.capacity * 400));
//              console.log('Filtered events data:', eventsData);
          }
      }
      setEvents(eventsData);
    }
  } catch (error) {
    console.error("Error fetching data: ", error);
  }
};
fetchData();
}, [restaurantDocId]); 


  return (
    <React.Fragment>
      <Title>最近のイベント</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>日付</TableCell>
            <TableCell>イベント名</TableCell>
            <TableCell>寄付先</TableCell>
            <TableCell>集客数</TableCell>
            <TableCell align="right">料金 (JPY)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {events.map((event) => (
            <TableRow key={event.id}>
              <TableCell>{new Date(event.date).toLocaleString()}</TableCell>
              <TableCell>{event.name}</TableCell>
              <TableCell>{event.organizer}</TableCell>
              <TableCell>{event.capacity}</TableCell>
              <TableCell align="right">{`${event.amount} JPY`}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Link color="primary" href="#" sx={{ mt: 3 }}>
        イベントをもっと見る
      </Link>
    </React.Fragment>
  );
}

export default Orders;
