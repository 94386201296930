import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { db } from "../../firebaseConfig";
import { useLocation } from 'react-router-dom';
import { addDoc, collection } from 'firebase/firestore';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function SurveyForm() {
  const query = useQuery();
  const [responses, setResponses] = useState({ question1: '', question2: '', question3: '' });
  // Store eventID and userID obtained from URL
  const [eventId, setEventId] = useState(null);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const eventID = query.get("eventId");
    const userID = query.get("userId");

    if (eventID && userID) {
      setEventId(eventID);
      setUserId(userID);
    }
  }, [query]);

  const handleChange = (event) => {
    setResponses({
      ...responses,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsSubmitting(true);

    try {
      await addDoc(collection(db, 'responses'), {
        userId,
        eventId,
        ...responses,
      });

      setResponses({ question1: '', question2: '', question3: '' });
    } catch (error) {
      console.error("Error adding document: ", error);
    }
    
    // 送信が完了したことを示します。
    setIsSubmitting(false);
  };

    return (
    <div className="container">
      <h1>食事会参加者アンケート</h1>

      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label>このイベントに参加した後の全体的な気分を評価するとどうなりますか？</Form.Label>
          <Form.Check type="radio" label="とてもよい" name="question1" value="とてもよい" onChange={handleChange} />
          <Form.Check type="radio" label="よい" name="question1" value="よい" onChange={handleChange} />
          <Form.Check type="radio" label="ふつう" name="question1" value="ふつう" onChange={handleChange} />
          <Form.Check type="radio" label="よくない" name="question1" value="よくない" onChange={handleChange} />
          <Form.Check type="radio" label="まったくダメ" name="question1" value="まったくダメ" onChange={handleChange} />
        </Form.Group>

        <Form.Group>
          <Form.Label>以下の文章にどの程度同意しますか？「このイベントに参加したことで、自分の置かれた状況をより深く理解し、サポートされていると感じることができた。」</Form.Label>
          <Form.Check type="radio" label="強く同意する" name="question2" value="強く同意する" onChange={handleChange} />
          <Form.Check type="radio" label="同意する" name="question2" value="同意する" onChange={handleChange} />
          <Form.Check type="radio" label="ふつう" name="question2" value="ふつう" onChange={handleChange} />
          <Form.Check type="radio" label="あまり同意できない" name="question2" value="あまり同意できない" onChange={handleChange} />
          <Form.Check type="radio" label="まったく同意できない" name="question2" value="まったく同意できない" onChange={handleChange} />
        </Form.Group>

        <Form.Group>
          <Form.Label>このイベントに参加した結果、あなたは自分の状況に対して希望や楽観的な気持ちを持つようになりましたか？</Form.Label>
          <Form.Check type="radio" label="強く持った" name="question3" value="強く持った" onChange={handleChange} />
          <Form.Check type="radio" label="持った" name="question3" value="持った" onChange={handleChange} />
          <Form.Check type="radio" label="ふつう" name="question3" value="ふつう" onChange={handleChange} />
          <Form.Check type="radio" label="あまり持てなかった" name="question3" value="あまり持てなかった" onChange={handleChange} />
          <Form.Check type="radio" label="まったく持てなかった" name="question3" value="まったく持てなかった" onChange={handleChange} />
        </Form.Group>

        <Button variant="primary" type="submit" disabled={isSubmitting}>
        {isSubmitting ? 'Submitting...' : 'Submit'}
        </Button>
      </Form>
    </div>
  );
}

export default SurveyForm;
