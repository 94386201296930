import React from 'react';
import { Box, Typography, Card } from "@mui/material";
import { styled, keyframes } from "@mui/system";

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const StyledCard = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(4),
  boxShadow: "0 5px 15px rgba(0, 0, 0, 0.1)",
  transition: "0.3s",
  backgroundColor: "rgba(255, 255, 255, 0.9)",
  animation: `${fadeIn} 2s ease-in`,
  "&:hover": {
    transform: "scale(1.03)",
    boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)",
  },
}));

function VisionPage() {
  return (
      <StyledCard>
        <Typography variant="h5" component="div" gutterBottom>
          なぜやるのか
        </Typography>
        <img 
          src="https://firebasestorage.googleapis.com/v0/b/gourmandy-2b224.appspot.com/o/etc%2Fvision.png?alt=media&token=your-token" 
          alt="ビジョンのイメージ" 
          style={{ width: '100%', maxWidth: '600px', marginTop: '20px' }}
        />
      </StyledCard>
  );
}

export default VisionPage;
