import React, { useEffect } from 'react';
import { Box, Card, Grid, Typography } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import ShareIcon from "@mui/icons-material/Share";
import LocalDiningRoundedIcon from '@mui/icons-material/LocalDiningRounded';
import { styled, keyframes } from "@mui/system";
import { MDBAccordion, MDBAccordionItem, MDBContainer } from "mdb-react-ui-kit";
import PropTypes from 'prop-types';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const StyledCard = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(4),
  height: "100%",
  boxShadow: "0 5px 15px rgba(0, 0, 0, 0.1)",
  transition: "0.3s",
  backgroundColor: "rgba(255, 255, 255, 0.9)",
  animation: `${fadeIn} 2s ease-in`,
  "&:hover": {
    transform: "scale(1.03)",
    boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)",
  },
}));

const InfoCard = ({ icon: IconComponent, title, descriptions }) => (
  <Grid item xs={12} md={4}>
    <StyledCard>
      <Grid container direction="row" alignItems="center" justify="center">
        <Grid item>
          <IconComponent style={{ fontSize: 60, color: "#deb887" }} />
        </Grid>
        <Grid item>
          <Typography variant="h5" component="div" gutterBottom>
            {title}
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="column" alignItems="flex-start">
        {descriptions && descriptions.map((description, index) => (
          <Typography key={index} variant="body1" color="text.secondary">
            {description}
          </Typography>
        ))}
      </Grid>
    </StyledCard>
  </Grid>
);

InfoCard.propTypes = {
  icon: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  descriptions: PropTypes.array.isRequired,
};

function HowItWorks() {
  const [key, setKey] = React.useState(Date.now());

  useEffect(() => {
    setKey(Date.now);
  }, []);

  useEffect(() => {
    document.body.classList.add('fadeIn');
  }, []);

  const infoCardsData = [
    {
      icon: LaunchIcon,
      title: "　立ち上げる",
      descriptions: [
        <React.Fragment key="1">
          <Typography variant="body1">
            <span style={{ marginRight: "0.5em" }}>&bull;</span> 目標寄付金額を決定する
          </Typography>
        </React.Fragment>,
        <React.Fragment key="2">
          <Typography variant="body1">
            <span style={{ marginRight: "0.5em" }}>&bull;</span> 食事会イベントを立ち上げる
          </Typography>
        </React.Fragment>,
        <React.Fragment key="2">
        <Typography variant="body1">
          <span style={{ marginRight: "0.5em" }}>&bull;</span> あなたの物語を伝える
        </Typography>
        </React.Fragment>,
        <React.Fragment key="3">
          <Typography variant="body1">
            <span style={{ marginRight: "0.5em" }}>&bull;</span>{" "}
            <a href="https://firebasestorage.googleapis.com/v0/b/gourmandy-2b224.appspot.com/o/etc%2F%E3%82%B5%E3%83%B3%E3%83%97%E3%83%ABPDF.pdf?alt=media&token=19f658bd-62eb-4708-be8a-cc5d7ee12079&_gl=1*wgkna4*_ga*NTA3OTg3NjAuMTY4MDU4MDQ4Nw..*_ga_CW55HF8NVT*MTY4NjI5Njk4OC40My4xLjE2ODYyOTcwNTguMC4wLjA." target="_blank" rel="noreferrer noopener" style={{ color: "black" }}>
              詳しい手順を確認
            </a>
          </Typography>
        </React.Fragment>,
      ],
    },
    {
      icon: ShareIcon,
      title: "　シェアする",
      descriptions: [
        <React.Fragment key="1">
          <Typography variant="body1">
            <span style={{ marginRight: "0.5em" }}>&bull;</span> メールを送信する
          </Typography>
        </React.Fragment>,
        <React.Fragment key="2">
          <Typography variant="body1">
            <span style={{ marginRight: "0.5em" }}>&bull;</span> チャットアプリでテキストを送信する
          </Typography>
        </React.Fragment>,
        <React.Fragment key="2">
        <Typography variant="body1">
          <span style={{ marginRight: "0.5em" }}>&bull;</span> SNSに共有する
        </Typography>
        </React.Fragment>,
        <React.Fragment key="3">
          <Typography variant="body1">
            <span style={{ marginRight: "0.5em" }}>&bull;</span>{" "}
            <a href="https://firebasestorage.googleapis.com/v0/b/gourmandy-2b224.appspot.com/o/etc%2F%E3%82%B5%E3%83%B3%E3%83%97%E3%83%ABPDF.pdf?alt=media&token=19f658bd-62eb-4708-be8a-cc5d7ee12079&_gl=1*wgkna4*_ga*NTA3OTg3NjAuMTY4MDU4MDQ4Nw..*_ga_CW55HF8NVT*MTY4NjI5Njk4OC40My4xLjE2ODYyOTcwNTguMC4wLjA." target="_blank" rel="noreferrer noopener" style={{ color: "black" }}>
              詳しい手順を確認
            </a>
          </Typography>
        </React.Fragment>,
      ],

    },
    {
      icon: LocalDiningRoundedIcon,
      title: "　食事を楽しむ",
      descriptions: [
        <React.Fragment key="1">
          <Typography variant="body1">
            <span style={{ marginRight: "0.5em" }}>&bull;</span> 飲む
          </Typography>
        </React.Fragment>,
        <React.Fragment key="2">
          <Typography variant="body1">
            <span style={{ marginRight: "0.5em" }}>&bull;</span> 食べる
          </Typography>
        </React.Fragment>,
        <React.Fragment key="2">
        <Typography variant="body1">
          <span style={{ marginRight: "0.5em" }}>&bull;</span> 笑う
        </Typography>
        </React.Fragment>,
        "　",
],

    },
  ];

  return (
    <Box
      py={8}
      px={{ xs: 2, sm: 4 }}
      sx={{
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        minHeight: '100vh',
      }}
    >
      <Typography variant="h3" component="h1" gutterBottom align="center">
        使い方について
      </Typography>
      <Grid container spacing={4}>
        {infoCardsData.map((infoCardData, index) => (
          <InfoCard
            key={index}
            icon={infoCardData.icon}
            title={infoCardData.title}
            descriptions={infoCardData.descriptions}
          />
        ))}
      </Grid>

        {/* ここからFAQのアコーディオン */}
        
        <Typography variant="h3" component="h1" gutterBottom align="center" mt={6}>
        よくある質問
        </Typography>
        <MDBContainer className="mt-3" style={{maxWidth: '1000px'}}>
          <MDBAccordion alwaysOpen initialActive={0}>
            <MDBAccordionItem collapseId={1} headerTitle="Question #1">
               ここにFAQの内容を書く 
            </MDBAccordionItem>
            <MDBAccordionItem collapseId={2} headerTitle="Question #2">
               ここにFAQの内容を書く 
            </MDBAccordionItem>
            <MDBAccordionItem collapseId={3} headerTitle="Question #3">
               ここにFAQの内容を書く 
            </MDBAccordionItem>
          </MDBAccordion>
        </MDBContainer>
        {/* ここまでFAQのアコーディオン */}
    </Box>

  );
}

export default HowItWorks;
