import React, { useEffect, useState } from "react";
import { Card, Button, Container, Row, Col } from "react-bootstrap";
import { getDocs, collection } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import { useNavigate ,useLocation} from "react-router-dom";
import "../../styles/customButton.css"
import { getAnalytics, logEvent } from "firebase/analytics";

const analytics = getAnalytics();

function logScreenView(screenName) {
  logEvent(analytics, "screen_view", {
    screen_name: screenName,
  });
}


function NonProfitListPage() {
  const [showSelectButton, setShowSelectButton] = useState(false);
  const [nonProfits, setNonProfits] = useState([]);
  const locationState = useLocation();

  useEffect(() => {
    document.title = 'NonProfitListPage';
    logScreenView('NonProfitListPage');
  }, []);


  useEffect(() => {
    setShowSelectButton(
      locationState.state?.fromCreateEventPage || new URLSearchParams(locationState.search).get("showSelectButton") === "true"
    );
  }, [locationState.search, locationState.state]);
  
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, "nonprofits"));
      const nonProfitsData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setNonProfits(nonProfitsData);
    };

    fetchData();
    logEvent(analytics, "fetch_data", { screen_name: "NonProfitListPage" });
  }, []);

  const handleSelectNonProfit = (nonProfit) => {
    localStorage.setItem("selectedNonProfitId", nonProfit.id);
    const updatedFormData = {
      ...locationState.state.currentFormData,
      organization: nonProfit.name,
    };
  
    navigate("/create-event", {
      replace: true,
      state: {
        fromCreateEventPage: locationState.state?.fromCreateEventPage || false,
        ...locationState.state,
        currentFormData: updatedFormData,
        selectedNonProfit: nonProfit,
      },
    });
  };
  
  const handleViewDetails = (nonProfitId) => {
    const state = {
      fromCreateEventPage: showSelectButton,
      currentFormData: locationState.state?.currentFormData,
      selectedRestaurant: locationState.state?.selectedRestaurant || null,
    };
    navigate(`/nonprofits/${nonProfitId}`, { state });
  };
  
  return (
    <Container>
      <h1 className="text-center mt-4">非営利組織一覧</h1>
      <Row className="mt-5">
        {nonProfits.map((nonProfit) => (
          <Col key={nonProfit.id} className="mb-4" sm={12} md={6} lg={4}>
            <Card>
              {nonProfit.image && (
                <Card.Img
                  variant="top"
                  src={nonProfit.image}
                  alt={nonProfit.name}
                />
              )}
              <Card.Body>
                <Card.Title>{nonProfit.name}</Card.Title>
                <Card.Text>{nonProfit.description}</Card.Text>
              </Card.Body>
              <Card.Footer>
                <div className="button-container">
                  {showSelectButton && (
                    <Button
                      onClick={() => handleSelectNonProfit(nonProfit)}
                      className="custom-button-select"
                    >
                      選択
                    </Button>
                  )}

                  <Button
                    onClick={() => handleViewDetails(nonProfit.id, true)}
                    className="btn custom-button-detail"
                  >
                    詳細ページ
                  </Button>
                </div>
              </Card.Footer>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default NonProfitListPage;
