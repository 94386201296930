import React from "react";
import { BrowserRouter ,useLocation } from "react-router-dom";
import { Router } from "./route/Router";
import Navigation from './Navbar';
import { AuthProvider } from "./AuthProvider";
import Footer from './Footer';
import './App.css';

function AppContent() {
  const location = useLocation();
  const isTop = location.pathname === "/";
  return (
    <div className={`app-container${isTop ? '' : ' fade-background'}`}>
    <div className="content-container">
      {!isTop && <Navigation />}
      <Router />
    </div>
    {!isTop && <Footer />}
  </div>
  )
}

export default function App() {
  return (
      <AuthProvider>
        <BrowserRouter>
          <AppContent />
        </BrowserRouter>
      </AuthProvider>
  );
}