import React, { useState, useEffect } from "react";
import { Container, Card, Row, Col, Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import "./RestaurantList.css";
import { getAnalytics, logEvent } from "firebase/analytics";
import { db } from "../../firebaseConfig";

const analytics = getAnalytics();

function logScreenView(screenName) {
  logEvent(analytics, "screen_view", {
    screen_name: screenName,
  });
}

function RestaurantListPage() {
  const { state: locationState } = useLocation();

  //イベント作成画面からの遷移（locationState オブジェクトの fromCreateEventPage プロパティの値がtrue）だったら変数showSelectButton, setShowSelectButtonに代入、それ以外はfalseを代入
  const [showSelectButton, setShowSelectButton] = useState(locationState?.fromCreateEventPage || false);
  const [restaurants, setRestaurants] = useState([]);
  const navigate = useNavigate();
  const storage = getStorage();

  async function getImageUrl(imagePath) {
//    console.log("imagePath:", imagePath);
//
if (!imagePath || imagePath.trim() === "") {
      return null;
    }
    
  
    try {
      const imageRef = ref(storage, imagePath);
      console.log("imageRef:", imageRef);
      const imageUrl = await getDownloadURL(imageRef);
      console.log("Download imageUrl:", imageUrl);
      return imageUrl;
    } catch (error) {
      console.error("Error getting image URL:", error);
      return null;
    }
  }

  useEffect(() => {
    document.title = 'RestaurantListPage';
    logScreenView('RestaurantListPage');
  }, []);


  //useEffect フックを使って、飲食店一覧画面で選択ボタンの表示・非表示を切り替える処理を実装
  useEffect(() => {
    setShowSelectButton(locationState?.fromCreateEventPage || false);
  }, [locationState]);
    
  //Firestoreデータベースから飲食店情報を取得して、それを restaurants ステート変数に設定
  useEffect(() => {
    const fetchRestaurants = async () => {
      const querySnapshot = await getDocs(collection(db, "restaurants"));
      console.log("querySnapshot.docs.length:", querySnapshot.docs.length);


      
      const restaurantData = await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          const data = doc.data();
          console.log(`data.image:`, data.image);
          const imageUrl = await getImageUrl(data.image);
          logEvent(analytics, "fetch_data", { screen_name: "RestaurantListPage" });
          console.log("imageUrl", imageUrl);
          return {
            id: doc.id,
            ...data,
            imageUrl,
          };
        })
      );
      setRestaurants(restaurantData);
    };
  
    fetchRestaurants();
  }, []);
    
  

  const handleSelectFromList = (restaurant, isDetail) => {
    //localStorageに選択された飲食店のIDを保存
    localStorage.setItem("selectedRestaurant", restaurant.id);
    const updatedFormData = {
//      ...locationState.state.currentFormData,
      ...(locationState?.currentFormData || {}),
      location: restaurant.name, // ここで location 情報を追加
    };
    //選択ボタンが表示(showSelectButtonがtrue）で且つ詳細ページを選択しない場合に実行される
    if (showSelectButton && !isDetail) {
      navigate("/create-event", {
        replace: true,
        state: {
          fromCreateEventPage: locationState?.fromCreateEventPage || false,
          ...locationState,
          currentFormData: updatedFormData,
          selectedRestaurant: restaurant, 
        },
      });
    //詳細ページ遷移時の処理
    } else {
      navigate(`/restaurant-detail/${restaurant.id}`, {
        state: {
//.stateを追加‗04141550
          ...locationState,
          fromCreateEventPage: showSelectButton,
          currentFormData: locationState?.currentFormData || {},
        },
      });
    }
  };
      

  return (
    <Container>
      <h1 className="text-center mt-4">飲食店一覧</h1>
      <Row className="mt-5">
        {restaurants.map((restaurant) => (
          <Col key={restaurant.id} className="mb-4" sm={12} md={6} lg={4}>
            <Card className="restaurant-card">
              {restaurant.imageUrl && (
                <div className="restaurant-card-img-wrapper">
  <Card.Img className="restaurant-card-img-top" variant="top" src={restaurant.imageUrl} alt={restaurant.name} />
</div>              )}
              <Card.Body>
                <Card.Title>{restaurant.name}</Card.Title>
                <Card.Text>住所: {restaurant.address}</Card.Text>
                <Card.Text>営業時間: {restaurant.hours}</Card.Text>
                <Card.Text>電話番号: {restaurant.phone}</Card.Text>
              </Card.Body>
              <Card.Footer>
  {showSelectButton && (
    <Button 
     onClick={() => handleSelectFromList(restaurant)} 
     className="custom-button-select"
     >
      選択
    </Button>
  )}
<Button
  onClick={() => handleSelectFromList(restaurant, true)}
  className="btn custom-button-detail"
>
  詳細ページ
</Button>

</Card.Footer>

            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default RestaurantListPage;