import { getFirestore, collection, addDoc } from "firebase/firestore";
import { app } from "./firebaseConfig";

const db = getFirestore(app);
const eventsCollection = collection(db, "events");

async function createEvent(eventData) {
  try {
    const docRef = await addDoc(eventsCollection, eventData);
    console.log("Document written with ID: ", docRef.id);
    return docRef.id;
  } catch (error) {
    console.error("Error adding document: ", error);
  }
}

export { createEvent };
