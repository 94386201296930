import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
  getDoc,
  doc,
  updateDoc,
  onSnapshot,
} from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { collection, query, getDocs } from "firebase/firestore";

async function getCurrentUser() {
  const authUser = auth.currentUser;
  if (!authUser) {
    return null;
  }

  const userRef = doc(db, "users", authUser.uid);
  const userDoc = await getDoc(userRef);
  return userDoc.data();
}


async function getEvents() {
  const eventsRef = collection(db, "events");
  const eventsQuery = query(eventsRef);
  const eventsSnapshot = await getDocs(eventsQuery);
  const events = eventsSnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  return events;
}

const firebaseConfig = {
  apiKey: "AIzaSyDjjF8FH-X-Ot2QICaXRrd_V97KuHU6v-E",
  authDomain: "gourmandy-2b224.firebaseapp.com",
  projectId: "gourmandy-2b224",
  storageBucket: "gourmandy-2b224.appspot.com",
  messagingSenderId: "164804191531",
  appId: "1:164804191531:web:8072802805f0589ea9116e",
  measurementId: "G-4F414DZ7VP"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export {app,firebaseConfig, auth, db, getAuth, getDoc, doc, updateDoc, onSnapshot, getEvents ,getCurrentUser};