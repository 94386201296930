import React, { useState, useEffect } from "react";
import { Container, Form, Button, Alert } from "react-bootstrap";


import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import { useAuth } from "../../AuthProvider";

function UserEditPage() {
  const { user } = useAuth();
  const [displayName, setDisplayName] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  const [introduction, setIntroduction] = useState("");
  const [userType, setUserType] = useState("");


  useEffect(() => {
    if (user) {
      (async () => {
        const userDoc = await getDoc(doc(db, "users", user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setDisplayName(userData.displayName);
          setIntroduction(userData.introduction);
          setUserType(userData.userType);
        }
      })();
    }
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");


  };

  return (
    <Container>
      <h1 className="text-center mt-4">ユーザ情報編集</h1>
      <Form onSubmit={handleSubmit}>
      <Form.Group controlId="userType">
        <Form.Label>ユーザータイプ</Form.Label>
          <Form.Select
            required
            value={userType}
            onChange={(e) => setUserType(e.target.value)}
            >
            <option value="">選択してください</option>
            <option value="個人">個人</option>
            <option value="飲食店">飲食店</option>
            <option value="寄付先">寄付先</option>
          </Form.Select>
        </Form.Group>


        <Form.Group>
          <Form.Label>名前</Form.Label>
          <Form.Control
            type="text"
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="introduction">
        <Form.Label>自己紹介</Form.Label>
        <Form.Control
            as="textarea"
            rows={3}
            value={introduction}
            onChange={(e) => setIntroduction(e.target.value)}
            />
        </Form.Group>

        {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
        <Button type="submit" className="w-100 mt-3">
          更新
        </Button>
      </Form>
    </Container>
  );
}

export default UserEditPage;
