import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import './Top.css'; // ここで CSS ファイルをインポートします

function SplashScreen() {
    const [fadeOut, setFadeOut] = useState(false);
    const navigate = useNavigate();
    const [zIndex, setZIndex] = useState(9999);
  
    useEffect(() => {
        const timer = setTimeout(() => {
          setFadeOut(true);
        }, 2000); // 8秒後にフェードアウトを開始
      
        const timer2 = setTimeout(() => {
          setZIndex(-1); // zIndexを-1に設定して他の要素の下に移動
        }, 2000); // 10秒後にzIndexを変更
      
        const timer3 = setTimeout(() => {
          navigate("/event-list");
        }, 3000); // 10秒後に画面遷移
      
        return () => {
          clearTimeout(timer);
          clearTimeout(timer2);
          clearTimeout(timer3);
        };
      }, [navigate]);
      
      return (
        <div
          className={`splash-screen ${fadeOut ? "fade-out" : ""}`} // fade-outクラスを適用
          style={{
            zIndex: zIndex,
            animationName: "fadeBackground",
            animationDuration: "2s",
            animationFillMode: "forwards",
          }}
        >
          <h1 className={`splash-title ${fadeOut ? "fade-out" : ""}`}>GOURMANDY</h1>
          <h2 className={`splash-subtitle ${fadeOut ? "fade-out" : ""}`}>課題の当事者による、当事者のためのファンドレイジングプラットフォーム</h2>
        </div>
      );
      
      
  }
  
  export default SplashScreen;