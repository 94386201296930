import React from "react";

const Footer = () => {
  return (
    <footer style={{ 
        marginTop: "40px" 
        }}>
      <p style={{ display: "inline-block" ,marginTop: "40px"}}>Copyright © 2023 GOURMANDY, Inc.</p>
      <nav style={{ display: "inline-block" }}>
        <ul>
          <li style={{ display: "inline-block", marginRight: "20px" }}><a href="/vision-page">About Me</a></li>
          <li style={{ display: "inline-block", marginRight: "20px" }}><a href="/contact-me">Contact Me</a></li>
{/*          
          <li style={{ display: "inline-block", marginRight: "20px" }}><a href="#">Terms of Service</a></li>
          <li style={{ display: "inline-block", marginRight: "20px" }}><a href="#">Privacy Policy</a></li>
*/}
        </ul>
      </nav>
    </footer>
  );
};

export default Footer;
