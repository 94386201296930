import React from "react";

const ContactMe = () => {
  return (
    <div style={{ padding: "40px", textAlign: "center" }}>
      <h2>Contact Me</h2>
      <p>ご質問やご連絡は、下記までお願いします：</p>
      <a href="mailto:your-email@example.com" style={{ fontSize: "20px", textDecoration: "none", color: "#333" }}>
        kazuyuki.yamada1@gmail.com
      </a>
    </div>
  );
};

export default ContactMe;
