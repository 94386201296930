import { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Container, ListGroup, Button, Card } from "react-bootstrap";
import {
  firebaseConfig,
  getAuth,
  getDoc,
  onSnapshot,
  updateDoc,
  doc,
  auth,
  db,
} from "../../firebaseConfig";
import "./EventDetail.css";
import { collection, getDocs } from "firebase/firestore";
import { addDoc } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faMapMarkerAlt,
  faHandHoldingHeart,
  faUsers,
  faTicketAlt,
} from "@fortawesome/free-solid-svg-icons";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { loadStripe } from "@stripe/stripe-js";
import { initializeApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAnalytics, logEvent } from "firebase/analytics";


const analytics = getAnalytics();

function logScreenView(screenName) {
  logEvent(analytics, "screen_view", {
    screen_name: screenName,
  });
}


function EventDetailPage() {
  const [showPendingApprovalMessage] = useState(false);
  const [eventData, setEventData] = useState(null);
  const [participantsData, setParticipantsData] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [isUpcomingEvent, setIsUpcomingEvent] = useState(false);
//  const [setIsUpcomingEvent] = useState(false);
  const isPastEvent = () => {
    return eventData && new Date() > new Date(eventData.dateTime);
  };
  
  const isHeldEvent = eventData && eventData.status === "held";
  const isExpiredEvent = eventData && new Date() > new Date(eventData.dateTime) && new Date() > new Date(eventData.dateTime) + (7 * 24 * 60 * 60 * 1000);
  const isEventExpired = (eventDateTime) => {
    const now = new Date();
    const eventDate = new Date(eventDateTime);
    return now > eventDate;
  };

  // Firebaseの初期化
  const app = initializeApp(firebaseConfig);
  const [capacity, setCapacity] = useState(0);

  const setCurrentUserWithLog = (user) => {
//    console.log("setCurrentUser called with:", user); // この行を追加
    setCurrentUser(user);
  };
  const [commentsData, setCommentsData] = useState([]);
  const { id } = useParams();

  const [commentText, setCommentText] = useState("");

  const isUserJoined = () => {
    if (!currentUser || !participantsData) {
      return false;
    }
//    console.log('Participants Data:', participantsData);
//    console.log('currentUser.uid:', currentUser);
    return participantsData.some(participant => participant.displayName === currentUser.displayName);
  };

  const [setIsCurrentUserParticipant] = useState(false);

  useEffect(() => {
    document.title = 'EventDetailPage';
    logScreenView('EventDetailPage');
  }, []);


  useEffect(() => {
    const joined = new URLSearchParams(window.location.search).get('joined');
    if (joined === 'true') {
      joinEvent(id, false);
    }
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const eventId = queryParams.get("eventId");
    const joined = queryParams.get("joined");
  
    if (eventId && joined === "true") {
      // showAlertsをtrueに設定して、メッセージを表示させる
      joinEvent(eventId, true, true);
      // パラメータを削除することで、メッセージが繰り返し表示されるのを防ぐ
      queryParams.delete("joined");
      window.history.replaceState({}, "", `${window.location.pathname}?${queryParams.toString()}`);
    }
  }, []);
    

  
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  async function redirectToCheckout(sessionId) {
    try {
      const stripe = await loadStripe("pk_test_51Mzr5tHbMwal8IGqEYbDSrd6RCun8SAU8bMPLA9ZD4WLGQafQZIWGVIPQtDvUD5xFoVYtWVylY576i5ttZW6CwMG00OcvZBpDH");
      const result = await stripe.redirectToCheckout({ sessionId: sessionId });
      if (result.error) {
        console.error("Error redirecting to checkout:", result.error.message);
      }
    } catch (error) {
      console.error("Error redirecting to checkout:", error);
    }
  }
  


  //イベントIDに関連付けられたコメントを取得するために、新しい useEffect を追加
  useEffect(() => {
    const fetchCommentsData = async () => {
      const commentsRef = collection(db, "events", id, "comments");
      const commentsSnapshot = await getDocs(commentsRef);
      const fetchedCommentsData = [];
      commentsSnapshot.forEach((doc) => {
        fetchedCommentsData.push({ id: doc.id, ...doc.data() });
      });
      setCommentsData(fetchedCommentsData);
    };
    fetchCommentsData();
    logEvent(analytics, "fetch_comments_data", { screen_name: "EventDetailPage" });
  }, [id]);

  
  useEffect(() => {
    const fetchEventData = async () => {
      const docRef = doc(db, "events", id);
      const docSnap = await getDoc(docRef);
  
      if (docSnap.exists()) {
        const data = docSnap.data();
        setEventData(data);
  
        // イベント開催日時が過ぎている場合、statusを更新する
        if (data.dateTime && isEventExpired(data.dateTime)) {
          const eventRef = doc(db, "events", id);
          await updateDoc(eventRef, {
            status: "expired", // 適切なstatus値に変更してください
          });
          logEvent(analytics, "update_event_status", { screen_name: "EventDetailPage" });
        }
      } else {
        console.log("No such document!");
      }
    };
  
    fetchEventData();
    logEvent(analytics, "fetch_event_data", { screen_name: "EventDetailPage" });
  }, [id, currentUser]);

  
  useEffect(() => {
    const fetchParticipants = () => {
      const eventRef = doc(db, "events", id);
  
      const unsubscribe = onSnapshot(eventRef, async (eventDocSnapshot) => {
        const eventData = eventDocSnapshot.data();
        const participantsField = eventData.participants || [];
        const fetchedParticipants = [];
  
        for (const participant of participantsField) {
          const userSnapshot = await getDoc(doc(db, "users", participant.uid));
          const userData = userSnapshot.data();
          fetchedParticipants.push({ id: participant.uid, ...userData });
        }
  
        setParticipantsData(fetchedParticipants);
      });
  
      return unsubscribe;
    };
  
    const unsubscribe = fetchParticipants();
    logEvent(analytics, "fetch_participants", { screen_name: "EventDetailPage" });
    return () => {
      unsubscribe();
    };
  }, [id]);
  
  
      
    
  useEffect(() => {
    const fetchCurrentUser = async () => {
      const user = getAuth().currentUser;
      if (user) {
        const userDoc = await getDoc(doc(db, "users", user.uid));
        setCurrentUserWithLog(userDoc.data()); // ここを変更
        //console.log("currentUser:", userDoc.data());
      } else {
        console.log("No user is logged in.");
      }
    };
  
//    console.log("fetchCurrentUser useEffect called");
    fetchCurrentUser();
    logEvent(analytics, "fetch_current_user", { screen_name: "EventDetailPage" });
  }, []);
  
    
  useEffect(() => {
//    console.log("isCurrentUserIndividualUser():", isCurrentUserIndividualUser());
  }, [currentUser]);


  useEffect(() => {
    if (eventData && participantsData) {
      setIsUpcomingEvent(
        eventData.status === "pending" && participantsData.length < eventData.capacity
        );
//        console.log("isUpcomingEvent:", isUpcomingEvent);
//        console.log("participantsData:", participantsData);
      }
  }, [eventData, participantsData]);
  
  

  function isCurrentUserIndividualUser() {
    return currentUser && currentUser.userType === "個人";
  }
  
  function isCurrentUserRestaurantUser() {
    return currentUser && currentUser.userType === "飲食店";
  }
  
  
  const joinEvent = async (id, skipJoinCheck = false, showAlerts = false) => {
    try {
      // id を利用して events コレクションの特定ドキュメントへの参照を作成
      const eventRef = doc(db, "events", id);
      // 現在のイベントデータを取得
      const eventDoc = await getDoc(eventRef);
      logEvent(analytics, "fetch_event_data", { screen_name: "EventDetailPage" });
      // eventDoc.data().participants から取得されたイベントの参加者リスト
      const participants = eventDoc.data().participants;
  
      // auth オブジェクトと currentUser を取得
      const auth = getAuth(app);
      const currentUser = auth.currentUser;
  
      // currentUser.uid が既に participants 配列内のいずれかの参加者と一致しているかどうかをチェック
      if (!skipJoinCheck && participants.some((p) => p.uid === currentUser.uid)) { // skipJoinCheckを使用
        alert("既にイベントに参加しています。");
        return;
      }


      // currentUser.uid が既に participants 配列内のいずれかの参加者と一致しているかどうかをチェック
      if (!participants.some((p) => p.uid === currentUser.uid)) {

        // currentUser の uid と displayName を participants 配列に追加
        participants.push({
          uid: currentUser.uid,
          displayName: currentUser.displayName,
        });
  
  
        await updateDoc(eventRef, {
          participants: participants,
        });
        logEvent(analytics, "update_participants", { screen_name: "EventDetailPage" });

      if (showAlerts) {
        alert("イベントに参加しました。");
      }
    } else {
      if (showAlerts) {
        alert("既にイベントに参加しています。");
      }
    }
    } catch (error) {
      console.error("Error joining event: ", error);
    }
  };


async function handleConfirmEvent() {
  if (!window.confirm("このイベントを確定しますか？")) {
    return;
  }

  try {
    const eventRef = doc(db, "events", id);
    // イベントデータを取得
    const eventDataSnapshot = await getDoc(eventRef);
    logEvent(analytics, "fetch_event_data_for_confirm", { screen_name: "EventDetailPage" });
    const eventData = eventDataSnapshot.data();

    // totalDonationAmountを計算
    let totalDonationAmount;
    if (eventData.donationEnabled) {
    // capacityの値がparticipantsData.lengthを超えないように制限
    const adjustedCapacity = Math.min(capacity, participantsData.length);
    totalDonationAmount = (adjustedCapacity - 1) * eventData.donationAmount;
    } else {
    totalDonationAmount = capacity * 100;
    }


    // Firestoreにステータス、参加人数、totalDonationAmountを更新
    await updateDoc(eventRef, {
      status: "confirmed",
      capacity: parseInt(capacity),
      totalDonationAmount: totalDonationAmount,
    });
    logEvent(analytics, "confirm_event", { screen_name: "EventDetailPage" });

    alert("イベントを確定しました。");
  } catch (error) {
    console.error(error);
    alert("イベントの確定に失敗しました。");
  }
}



async function handleCancelEvent() {
  if (!window.confirm("このイベントをキャンセルしますか？")) {
    return;
  }

  try {
    const eventRef = doc(db, "events", id);
    await updateDoc(eventRef, { status: "cancelled" });
    logEvent(analytics, "cancel_event", { screen_name: "EventDetailPage" });
    alert("イベントをキャンセルしました。");
  } catch (error) {
    console.error(error);
    alert("イベントのキャンセルに失敗しました。");
  }
}





    



useEffect(() => {
  const unsubscribe = onSnapshot(
    collection(db, "events", id, "participants"),
    (snapshot) => {
      const fetchedParticipants = [];
      snapshot.forEach((doc) => {
        fetchedParticipants.push({ id: doc.id, ...doc.data() });
      });
      setParticipantsData(fetchedParticipants);
    }
  );
  logEvent(analytics, "fetch_participants_data", { screen_name: "EventDetailPage" });
  return () => unsubscribe();
}, [id]);


useEffect(() => {
  const checkIsCurrentUserParticipant = async () => {
    if (currentUser && eventData) {
      const participantsRef = collection(db, "events", id, "participants");
      const participantSnapshot = await getDocs(participantsRef);
      logEvent(analytics, "check_current_user_participant", { screen_name: "EventDetailPage" });
      const foundParticipant = participantSnapshot.docs.find(
        (doc) => doc.data().uid === currentUser.uid
      );
      setIsCurrentUserParticipant(Boolean(foundParticipant));
    } else {
      setIsCurrentUserParticipant(false);
    }
  };
  checkIsCurrentUserParticipant();
}, [currentUser, eventData, id]);


//getAuth関数を使用して現在のユーザー情報を取得,Firebaseのcollection関数を使って、イベントのコメントコレクションへの参照を取得
//addDoc関数を使って、新しいコメントをデータベースに追加
//コメントのデータには、コメントのテキスト、投稿者の名前、投稿者のID、コメントの作成日時が含まれる
const addComment = async () => {
  try {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    const commentsRef = collection(db, "events", id, "comments");
    await addDoc(commentsRef, {
      text: commentText,
      author: currentUser.displayName,
      authorId: currentUser.uid,
      createdAt: new Date(),
    });
    logEvent(analytics, "add_comment", { screen_name: "EventDetailPage" });

    setCommentText("");
    alert("コメントが追加されました。");
  } catch (error) {
    console.error("Error adding comment: ", error);
  }
};


//イベントが開催されてからイベント終了後1か月以内にあるかどうかを判定
function isWithinOneMonth(createdDate, eventDate) {
  const now = new Date();
  const startDate = new Date(createdDate);
  const endDate = new Date(eventDate);
  const oneMonthLater = new Date(endDate);
  oneMonthLater.setMonth(endDate.getMonth() + 1);  
  return now >= startDate && now <= oneMonthLater;
}

    //現在のユーザーがコメントを投稿できるかどうかを判断する関数
    const canUserComment = () => {
      const auth = getAuth();
      const currentUser = auth.currentUser;
      console.log("currentUser:",currentUser);
      if (!eventData) return false; // イベントデータが読み込まれていない場合はコメント不可
    
      const isParticipant = eventData.participants?.some(
        (p) => p.uid === currentUser?.uid // ログインしているユーザーが参加者かどうかチェック
      );
      return isParticipant && isWithinOneMonth(eventData.createdDate, eventData.dateTime);
    };
                
        
    
  // 以下の箇所で、条件付きレンダリングを修正
  {eventData && eventData.status === "cancelled" && (
    <p>このイベントはキャンセルされました。</p>
  )}
  {eventData && eventData.status === "confirmed" && (
    <p>このイベントは確定されました。</p>
  )}
  {showPendingApprovalMessage && (
    <p>飲食店の承認待ちです。</p>
  )}
  
  
  
  function calculateRemainingTime(eventDateTime) {
    const now = new Date();
    const eventDate = new Date(eventDateTime);
    const remainingTime = eventDate - now;  
    if (remainingTime <= 0) {
      return "募集終了";
    }
    const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
    const hours = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
    return `${days}日 ${hours}時間 ${minutes}分`;
  }


  






  


async function handlePurchaseTicket(eventId) {
  // Firebaseの初期化
  const app = initializeApp(firebaseConfig);
  //Firebase Cloud Functions の API を取得
  //const functions = getFunctions(app);
  const functions = getFunctions(app, 'us-central1');
  
  const createCheckoutSession = httpsCallable(functions, 'createCheckoutSession');

  const eventRef = doc(db, 'events', eventId.toString());
  //await joinEvent(eventId, true);

  try {
    const eventDoc = await getDoc(eventRef);
    const amount = eventDoc.data().donationAmount;

    const user = auth.currentUser;
    const idToken = await user.getIdToken();


    //0429追記
      const originUrl = window.location.href;

    const result = await createCheckoutSession({
        amount: amount,
        idToken: idToken,
    //0429追記
        success_url: `${originUrl}?eventId=${eventId}&joined=true`,
        cancel_url: originUrl,
    });

    //console.log("result",result)

    if (!result) {
      console.error("Checkout session result is undefined or null");
      return;
    }

    const data = result.data;
    const sessionId = result.data.sessionId;

    await redirectToCheckout(sessionId);
    
    alert('Purchase successful!');
    console.log("1", data); // 結果をコンソールに出力する
  } catch (error) {
    console.error("2", error); // エラー内容をコンソールに出力する
  }
}
  

return (
  <Container className="event-detail-container">
    {eventData && (
      <>
        <h1 className="text-center my-4">{eventData.title}</h1>
        <div className="event-detail-grid">
          <div className="event-info">
            <img
              className="event-image img-fluid rounded"
              src={eventData.imageUrl}
              alt={eventData.title}
            />
            <div className="status-label-container mt-3">
              {eventData && eventData.status === "pending" && (
                <span className="status-label warning">開催前</span>
              )}
              {eventData && eventData.status === "cancelled" && (
                <span className="status-label danger">キャンセル済み</span>
              )}
              {eventData && eventData.status === "confirmed" && (
                <span className="status-label success">確定済み</span>
              )}
              {eventData && eventData.status === "held" && (
                <span className="status-label">開催済み</span>
              )}
              {eventData && eventData.status === "expired" && (
                <span className="status-label">期限切れ</span>
              )}
              {showPendingApprovalMessage && (
                <span className="status-label">飲食店の承認待ち</span>
              )}
              {isHeldEvent && (
                <span className="status-label">イベントが開催されました</span>
              )}
              {isExpiredEvent && (
                <span className="status-label">イベントがキャンセルされました</span>
              )}
            </div>
          </div>

          <div>
            <Card className="info-card mb-4">
              <Card.Header>
                <h2>イベント情報</h2>
              </Card.Header>
              <Card.Body>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <FontAwesomeIcon icon={faClock} /> 開催日時:{" "}
                    {new Date(eventData.dateTime).toLocaleDateString()}{" "}
                    {new Date(eventData.dateTime).toLocaleTimeString()}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <FontAwesomeIcon icon={faMapMarkerAlt} /> 開催場所:{" "}
                    {eventData.location}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <FontAwesomeIcon icon={faHandHoldingHeart} /> 寄付先:{" "}
                    {eventData.organization}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <FontAwesomeIcon icon={faHandHoldingHeart} /> 寄付金額:{" "}
                    {eventData.totalDonationAmount} 円
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <FontAwesomeIcon icon={faUsers} /> 参加者(
                      {eventData.capacity}/{participantsData.length})
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <FontAwesomeIcon icon={faClock} /> 募集終了まで残り:{" "}
                    {calculateRemainingTime(eventData.dateTime)}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <FontAwesomeIcon icon={faTicketAlt} /> チケット料金:{" "}
                    {eventData.donationAmount}
                    円
                  </ListGroup.Item>
                </ListGroup>
              </Card.Body>
            </Card>
          </div>

          <div>
            <Card className="attendees-card mb-4">
              <Card.Header>
                <h2>参加者</h2>
              </Card.Header>
              <Card.Body className="participants-container">
                {participantsData.map((participant, index) => {
                  return (
                    <div key={index} className="participant-card">
                      <Link to={`/user-profile/${participant.id}`}>
                        {participant.iconUrl ? (
                          <img
                            src={participant.iconUrl}
                            alt={participant.displayName}
                          />
                        ) : (
                          <i className="fas fa-user-circle"></i>
                        )}
                        <span>{participant.displayName}</span>
                      </Link>
                    </div>
                  );
                })}
              </Card.Body>
            </Card>

            <Card className="comments-card mb-4">
              <Card.Header>
                <h2>コメント</h2>
              </Card.Header>
              <Card.Body>
                <ListGroup>
                  {commentsData.map((comment, index) => (
                    <ListGroup.Item key={index}>
                      <strong className="author">{comment.author}:</strong>{" "}
                      {comment.text}
                    </ListGroup.Item>
                  ))}
                </ListGroup>

                {/* コメントフォームのコード */}
                {canUserComment() ? (
  <div className="comment-form">
    <input
      type="text"
      placeholder="コメントを入力してください"
      value={commentText}
      onChange={(e) => setCommentText(e.target.value)}
    />
    <button onClick={addComment} className="custom-button-detail">
      コメントを送信
    </button>
  </div>
) : (
  <p>コメントは参加者のみ可能です。</p>
)}
              </Card.Body>
            </Card>
          </div>
        </div>
        {/*0429一部変更*/}
        {isCurrentUserIndividualUser() &&
        eventData &&
        eventData.status === "pending" &&
        participantsData.length < eventData.capacity && (
          <>
            {isUserJoined() ||
            new URLSearchParams(window.location.search).get(
              "joined"
            ) === "true" ? (
              <p>参加済みです</p>
            ) : eventData.donationEnabled ? (
              <Button
                onClick={() => handlePurchaseTicket(id)}
                className="custom-button-purchase"
              >
                チケットを購入する
              </Button>
            ) : (
              <Button
                onClick={() => joinEvent(id)}
                className="custom-button-select"
              >
                イベントに参加する
              </Button>
            )}
          </>
        )}

        {isCurrentUserIndividualUser() &&
        eventData &&
        eventData.status === "pending" &&
        isUserJoined() && <p>参加済みです</p>}

        {isCurrentUserRestaurantUser() &&
        isPastEvent(eventData.dateTime) &&
        !isHeldEvent &&
        !isExpiredEvent && (
          <div>
            <label htmlFor="capacity">参加人数：</label>
            <input
              type="number"
              id="capacity"
              name="capacity"
              min={participantsData.length}
              defaultValue={participantsData.length}
              onChange={(e) => setCapacity(e.target.value)}
            />
            <Button onClick={() => handleConfirmEvent(capacity)}>イベントを確定する</Button>
            <Button onClick={handleCancelEvent}>イベントをキャンセルする</Button>
          </div>
        )}
      </>
    )}
  </Container>
  );
}

export default EventDetailPage;

