  import React, { useState } from "react";
  import { Container, Button, Row, Col, Spinner, Alert ,Form, OverlayTrigger, Tooltip} from "react-bootstrap";
  import { createEvent } from "../../createEvent";
  import { getAuth } from "firebase/auth";
  import { useEffect } from "react";
  import { useNavigate, useLocation } from "react-router-dom";
  import { doc, getDoc } from "firebase/firestore";
  import { db } from "../../firebaseConfig";
  import { getStorage, ref, uploadBytes } from "firebase/storage";
  import { getDownloadURL } from "firebase/storage";
  import "../../styles/customButton.css";
  import "./CreateEvent.css";
  import { useSpring, animated } from 'react-spring';
  import { getAnalytics, logEvent } from "firebase/analytics";

  const analytics = getAnalytics();

  function logScreenView(screenName) {
    logEvent(analytics, "screen_view", {
      screen_name: screenName,
    });
  }
  

  function EventCreationPage() {
    const [imageUrl, setImageUrl] = useState('');
//    const [setImageUrl] = useState("");
    const locationState = useLocation().state;
    const [selectedRestaurant, setSelectedRestaurant] = useState(locationState?.selectedRestaurant || null);
    const [selectedNonProfit, setSelectedNonProfit] = useState(locationState?.selectedNonProfit || null);
    
    const [selectedRestaurantId] = useState(locationState?.selectedRestaurant?.id || null);
    const [selectedNonProfitId] = useState(locationState?.selectedNonProfit?.id || null);
    const location = useLocation();
    const [donationSwitch, setDonationSwitch] = useState(location.state?.donationSwitchState || false);
    const [isError, setIsError] = useState(false);

    const navigate = useNavigate();
    //飲食店一覧ページに遷移する(stateとして3つの情報を渡す）
    const handleNavigateToRestaurantList = () => {
      navigate("/restaurant-list", {
        state: {
          fromCreateEventPage: true,  //イベント作成画面からの遷移であることを示す
          currentFormData: eventData, //ベント作成画面の入力フォーム情報
          selectedNonProfit, //  ユーザーがフォームで指定した寄付先の情報
          donationSwitchState: donationSwitch,
        },
      });
    };
    
    const donationFormAnimation = useSpring({
      from: { opacity: 0, transform: 'translateY(30px)' },
      to: {
        opacity: donationSwitch ? 1 : 0,
        transform: donationSwitch ? 'translateY(0)' : 'translateY(30px)',
      },
      config: { duration: 1000 },
    });

    const storage = getStorage();

useEffect(() => {
  document.title = 'CreateEventPage';
  logScreenView('CreateEventPage');
}, []);


    useEffect(() => {
      setSelectedNonProfit(locationState?.selectedNonProfit || null);
      setSelectedRestaurant(locationState?.selectedRestaurant || null); // この行を追加
    }, [locationState]);
          
const handleImageUpload = (e) => {
  const file = e.target.files[0];
  if (!file) {
    return;
  }

  const uniqueFileName = `${Date.now()}_${file.name}`;
  const storageRef = ref(storage, `eventImages/${uniqueFileName}`);
//  console.log("storageRef: ", storageRef);
  uploadBytes(storageRef, file)
    .then(() => {
      console.log("File uploaded successfully!");
      return getDownloadURL(storageRef);
    })
    .then((imageUrl) => {
      setEventData({ ...eventData, imageUrl: imageUrl });
      //setImageUrl(imageUrl);
    })
    .then((url) => {
      setImageUrl(url);
    })
        .catch((error) => {
      console.error("Error uploading file: ", error)
    });
    
};


    useEffect(() => {
      const checkUserRegistration = async () => {
        const auth = getAuth();
        const currentUser = auth.currentUser;

        if (currentUser) {
          const userDocRef = doc(db, "users", currentUser.uid);
          const userDocData = await getDoc(userDocRef);

          if (!userDocData.exists()) {
            navigate("/register");
          }
        }
      };

      checkUserRegistration();
    }, []);

    useEffect(() => {
//      console.log("Selected Restaurant ID: ", selectedRestaurant);
//      console.log("Selected NonProfit ID: ", selectedNonProfit);
      const fetchSelectedRestaurant = async () => {
        //selectedRestaurantIdが存在する場合、Firestore の restaurants コレクションから、その ID に対応するドキュメントを取得
        if (selectedRestaurantId) {
          const docRef = doc(db, "restaurants", selectedRestaurantId);
          const docSnap = await getDoc(docRef);
        //ドキュメントが存在する場合、ドキュメントのデータを取得し、setSelectedRestaurant を使ってコンポーネントの状態を更新
          if (docSnap.exists()) {
            const data = docSnap.data();
            setSelectedRestaurant(data);
        //ドキュメントが存在しない場合、コンソールにメッセージを表示します。
          } else {
            console.log("No such document!");
          }
        }
      };
      fetchSelectedRestaurant();
    }, [selectedRestaurantId]);
    
    useEffect(() => {
      const fetchSelectedNonProfit = async () => {
        //selectedNonProfitId が存在する場合、Firestore の nonprofits コレクションから、その ID に対応するドキュメントを取得
        if (selectedNonProfitId) {
          const docRef = doc(db, "nonprofits", selectedNonProfitId);
          const docSnap = await getDoc(docRef);
        //ドキュメントが存在する場合、ドキュメントのデータを取得し、setSelectedNonProfit を使ってコンポーネントの状態を更新
          if (docSnap.exists()) {
            const data = docSnap.data();
            setSelectedNonProfit(data);
        //ドキュメントが存在しない場合、コンソールにメッセージを表示
          } else {
            console.log("No such document!");
          }
        }
      };
      fetchSelectedNonProfit();
    }, [selectedNonProfitId]);
    
    
    const [eventData, setEventData] = useState({
      eventName: locationState?.currentFormData?.eventName || JSON.parse(localStorage.getItem("eventData"))?.eventName || "",
      dateTime: locationState?.currentFormData?.dateTime || JSON.parse(localStorage.getItem("eventData"))?.dateTime || "",
      imageUrl: locationState?.currentFormData?.imageUrl || JSON.parse(localStorage.getItem("eventData"))?.imageUrl || "",
      location: locationState?.currentFormData?.location || JSON.parse(localStorage.getItem("eventData"))?.location || "",
      organization: locationState?.currentFormData?.organization || JSON.parse(localStorage.getItem("eventData"))?.organization || "",
      capacity: locationState?.currentFormData?.capacity || JSON.parse(localStorage.getItem("eventData"))?.capacity || "",
      donationAmount: locationState?.currentFormData?.donationAmount || JSON.parse(localStorage.getItem("eventData"))?.donationAmount || "",
      organizerMessage: locationState?.currentFormData?.organizerMessage || JSON.parse(localStorage.getItem("eventData"))?.organizerMessage || "",
      donationPurpose: locationState?.currentFormData?.donationPurpose || JSON.parse(localStorage.getItem("eventData"))?.donationPurpose || "",
      totalDonationAmount: locationState?.currentFormData?.totalDonationAmount || JSON.parse(localStorage.getItem("eventData"))?.totalDonationAmount || "",    
    });        

    const [isFormReset, setIsFormReset] = useState(false);

    useEffect(() => {
      localStorage.setItem("eventData", JSON.stringify(eventData));
    }, [eventData, isFormReset]);


    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState(null);

    const handleChange = (e) => {
      const { name, value } = e.target;
    
      if (name === "donationAmount" && (value < 100 || value >= 2501)) {
        setIsError(true);
      } else {
        setIsError(false);
      }
    
      if (name === "donationAmount" || name === "capacity") {
        const newDonationAmount = donationSwitch && name === "donationAmount" ? parseInt(value) : eventData.donationAmount;
        const newCapacity = name === "capacity" ? parseInt(value) : eventData.capacity;
        const newTotalDonationAmount = (donationSwitch ? newDonationAmount : 100) * newCapacity;
    
        setEventData((prevData) => ({
          ...prevData,
          [name]: value,
          donationAmount: newDonationAmount,
          totalDonationAmount: newTotalDonationAmount,
        }));
      } else {
        setEventData({ ...eventData, [name]: value });
      }
    };

    
    const handleSubmit = async (e) => {
      e.preventDefault();
      setIsLoading(true);
      console.log("donationSwitch value:", donationSwitch); 
      const auth = getAuth();
      const currentUser = auth.currentUser;
      if (!currentUser) {
        alert("ログインしてください。");
        setIsLoading(false);
        return;
      }


      setImageUrl("");

      const newEventData = {
        eventName: eventData.eventName,
        dateTime: eventData.dateTime,
        imageUrl: eventData.imageUrl, // imageUrl を使用
        location: selectedRestaurant.name, // 他の項目のようにフォームに入力した値ではなく、選択された飲食店と非営利団体の ID を使用する必要
        organization: selectedNonProfit.name, // 同上(文末から"ID"を削除,.idフィールドを追加_0414_12:11)
        donationPurpose: eventData.donationPurpose,
        capacity: eventData.capacity,
        organizerMessage: eventData.organizerMessage,
        createdBy: currentUser.uid,
        participants: [
          {
            uid: currentUser.uid,
            displayName: currentUser.displayName,
          },
        ],
        status: "pending",
        createdDate: new Date().toISOString(),
        donationAmount: Number(eventData.donationAmount),
        totalDonationAmount: Number(eventData.totalDonationAmount),
        donationEnabled: donationSwitch,
      };
        // 追加したコード
      if (donationSwitch) {
      newEventData.donationEnabled = true;
      } else {
      newEventData.donationEnabled = false;
      }

      const eventId = await createEvent(newEventData);
      console.log("Created event with ID: ", eventId);

      // Firebase Analyticsにログイベントを送信
      logEvent(analytics, "event_created", { screen_name: "CreateEventPage" });

      setIsLoading(false);
      setMessage("イベントが作成されました。");

      setEventData({
        eventName: "",
        dateTime: "",
        imageUrl: "",
        location: "",
        organization: "",
        capacity: "",
        organizerMessage: "",
        donationPurpose: "",
      });
      
      setSelectedRestaurant(null);
      setSelectedNonProfit(null);
//      localStorage.removeItem("selectedRestaurant");
//      localStorage.removeItem("selectedNonProfitId");
//      localStorage.removeItem("eventData");

      // フォームがリセットされたことを示すフラグを切り替える
      setIsFormReset((prev) => !prev);
    };
    
      

    return (
      <Container className="d-flex justify-content-center align-items-center create-event-container">
      <Form onSubmit={handleSubmit} className="mt-5 event-form">
        <h1 className="text-center mb-4">イベント作成ページ</h1>
          {message && <Alert variant="success" onClose={() => setMessage(null)} dismissible>{message}</Alert>}

          <Form.Group controlId="donationSwitch" className="mb-4">
  <div style={{ display: "flex", flexDirection: "column" }}>
    <Form.Label>寄付チケット</Form.Label>
    <OverlayTrigger
      placement="right"
      overlay={
        <Tooltip id="donationSwitchTooltip">
          オンにすると、イベントに参加するための寄付チケットを設定できます(手数料0円)。
          オフにした場合も参加者一人につき100円が寄付されます。
        </Tooltip>
      }
    >
      
      <Form.Check
        type="switch"
        id="donationSwitch"
        onChange={(e) => setDonationSwitch(e.target.checked)}
        checked={donationSwitch}
        className="custom-switch"
      />
    </OverlayTrigger>
  </div>
</Form.Group>


          {donationSwitch && (
            <animated.div style={donationFormAnimation}>
            <Form.Group controlId="donationAmount" className="mb-3">
    <Form.Label>寄付チケット金額</Form.Label>
    <Form.Control
      type="number"
      name="donationAmount"
      value={eventData.donationAmount}
      onChange={handleChange}
      required
      placeholder="半角数字を入力してください"
    />
          {isError && (
        <Form.Text className="text-danger">
          金額は101円～2500円の範囲で入力してください。
        </Form.Text>
      )}
  </Form.Group>
</animated.div>
)}

<Form.Group controlId="eventName" className="mb-4">
          <Form.Label>イベント名</Form.Label>
          <OverlayTrigger
            trigger={['hover', 'focus', 'click']} // ホバー、フォーカス、クリックのいずれかでツールチップを表示
            placement="right"
            overlay={
              <Tooltip id="donationSwitchTooltip">
                見た人がイベントに参加したくなるようなイベント名を考えてください。
              </Tooltip>
            }
          >
            <Form.Control
              type="text"
              name="eventName"
              value={eventData.eventName}
              onChange={handleChange}
              required
            />
          </OverlayTrigger>
        </Form.Group>

        <Form.Group controlId="dateTime" className="mb-4">
          <Form.Label>開催日時</Form.Label>
          <Form.Control
            type="datetime-local"
            name="dateTime"
            value={eventData.dateTime}
            onChange={handleChange}
            required
          />
        </Form.Group>

      <Form.Group controlId="location" className="mb-2">
        <Form.Label>開催場所（飲食店）</Form.Label>
        <Form.Label>選択された飲食店: {selectedRestaurant && selectedRestaurant.name}</Form.Label>
        <br />
        <OverlayTrigger
          placement="right"
          overlay={
          <Tooltip id="location">
            飲食店への予約も忘れずに行いましょう。
          </Tooltip>
        }
        >
        <Button onClick={handleNavigateToRestaurantList} className="mb-3 custom-button-select">
        飲食店を選択
        </Button>
        </OverlayTrigger>
      </Form.Group>

      <Form.Group controlId="organization" className="mb-2">
        <Form.Label>寄付先非営利組織</Form.Label>
        <Form.Label>選択された非営利組織: {selectedNonProfit && selectedNonProfit.name}</Form.Label>
        <div className="mb-3">
        <OverlayTrigger
          placement="right"
          overlay={
          <Tooltip id="organization">
          支援する非営利組織を選びます。非営利組織のHPや欲しいものリストもしっかりチェックしましょう！
          </Tooltip>
        }
        >
        <Button
          onClick={() => {
            navigate("/nonprofits", {
              state: {
              fromCreateEventPage: true,
              currentFormData: eventData,
              selectedRestaurant, 
              donationSwitchState: donationSwitch,
              },
            });
          }}
          className="mb-3 custom-button-select"
        >
          寄付先を選択
        </Button>
        </OverlayTrigger>

        </div>
      </Form.Group>
      
      <Form.Group controlId="donationPurpose" className="mb-4">
        <Form.Label>寄付の使途について（希望）</Form.Label>
        <OverlayTrigger
          placement="right"
          overlay={
          <Tooltip id="donationSwitchTooltip">
            指定した寄付先の欲しいものリストの中から買ってほしいアイテムを書きましょう。
          </Tooltip>
        }
        >
  <Form.Control
    type="text"
    name="donationPurpose"
    value={eventData.donationPurpose}
    onChange={handleChange}
    placeholder="寄付の使途について入力してください"
  />
  </OverlayTrigger>
</Form.Group>

      <Form.Group controlId="participants" className="mb-0.5">
        <Form.Label>募集人数</Form.Label>
        <Form.Control
          type="number"
          name="capacity"
          value={eventData.capacity}
          onChange={handleChange}
          required
        />
  {eventData.capacity && (
            <OverlayTrigger
            placement="right"
            overlay={
            <Tooltip id="participants">
            寄付チケットは幹事分が含まれません。
            </Tooltip>
          }
          >
    <p>
      合計寄付金額:{" "}
      {donationSwitch
        ? eventData.donationAmount * (eventData.capacity - 1)
        : 100 * eventData.capacity}
      円
    </p>
    </OverlayTrigger>
  )}

  <div style={{ height: "16px" }}></div>
</Form.Group>



<Form.Group controlId="uploadFile" className="mb-4">
{isFormReset && <input type="reset" style={{ display: "none" }} />}
  <Form.Label>ファイルアップロード</Form.Label>
  <OverlayTrigger
          placement="right"
          overlay={
          <Tooltip id="uploadFile">
          イベントのイメージが伝わるような写真を設定しましょう！
          </Tooltip>
        }
        >

  <Form.Control type="file" onChange={handleImageUpload} />
  </OverlayTrigger>
</Form.Group>


<Form.Group controlId="organizerMessage" className="mb-4">
  <Form.Label>主催者からのメッセージ</Form.Label>
  <OverlayTrigger
          placement="right"
          overlay={
          <Tooltip id="organizerMessage">
          あなたがなぜこのイベントを開催するのか書きましょう。あなたの物語を聞かせてください。
          </Tooltip>
        }
        >

  <Form.Control
    as="textarea"
    rows={3}
    name="organizerMessage"
    value={eventData.organizerMessage}
    onChange={handleChange}
    placeholder="主催者からのメッセージを入力してください"
  />
        </OverlayTrigger>
</Form.Group>



<Row className="justify-content-center mt-4">
          <Col xs={12} md={6} className="text-center">
            <Button variant="success" type="submit" disabled={isLoading} className="custom-button-decision">
              {isLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="mr-2"
                />
              ) : (
                "イベントを作成"
              )}
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}

export default EventCreationPage;