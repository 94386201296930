import React, { useState, useEffect } from "react";
import { useAuth } from "../../AuthProvider";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebaseConfig";

const ProfilePage = () => {
  const { user, loading } = useAuth();
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams(); // パラメータからユーザIDを取得

  useEffect(() => {
    document.title = 'ProfilePage';
//    logScreenView('ProfilePage');
  }, []);


  useEffect(() => {
    //ユーザ未ログイン時はログイン画面に遷移
    if (!user) {
      if (!loading) {
        navigate("/sign-in");
      }
    } else {
      const fetchData = async () => {
        const userDoc = await getDoc(doc(db, "users", id)); // パラメータから取得したユーザIDを使用してユーザドキュメントを取得
        //ログインユーザ情報がDBに存在する場合
        if (userDoc.exists()) {
          setUserData(userDoc.data());
        } else {
          navigate("/register");
        }
      };
      fetchData();
    }
  }, [user, loading, navigate, id]);

  const handleEditClick = () => {
    navigate("/user-edit");
  };

  if (!user || !userData || loading) {
    return null;
  }

  return (
    <div>
      <h1>Profile Page</h1>
      <p>UserType: {userData.userType}</p>
      <p>Name: {userData.displayName}</p>
      <p>Email: {userData.email}</p>
      <p>Introduction: {userData.introduction}</p>
      {userData.iconUrl && ( // アイコンURLが存在する場合にのみ表示する
        <img src={userData.iconUrl} alt="user-icon" style={{ width: "100px" }} />
      )}
      <Button onClick={handleEditClick}>編集</Button>
    </div>
  );
};

export default ProfilePage;
