import React, { useState, useEffect } from "react";
import { Container, Form, Button, Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getAuth, updateProfile } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"; // Add this line at the top of the file
import { getAnalytics, logEvent } from "firebase/analytics";

const analytics = getAnalytics();




function UserRegistrationPage() {
  const [userType, setUserType] = useState(""); // userType ステートを追加
  const [displayName, setDisplayName] = useState("");
  const [email, setEmail] = useState(""); // Add this line
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [introduction, setIntroduction] = useState("");

  useEffect(() => {
    const auth = getAuth();
    if (auth.currentUser) {
      setEmail(auth.currentUser.email);
    }
  }, []);

const storage = getStorage();

const [icon, setIcon] = useState(null);
const [setIconUrl] = useState("");

const handleIconChange = (e) => {
  const file = e.target.files[0];
  if (file) {
    setIcon(file);
  }
};

const handleSubmit = async (e) => {
  e.preventDefault();
  setErrorMessage("");

  const auth = getAuth();
  const currentUser = auth.currentUser;
  try {
    await updateProfile(currentUser, { displayName });

    if (icon) {
      const storageRef = ref(storage, `userIcons/${currentUser.uid}`);
      const uploadTask = uploadBytesResumable(storageRef, icon);

      uploadTask.on(
        "state_changed",
        null,
        (error) => {
          setErrorMessage(error.message);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          setIconUrl(downloadURL);

          await setDoc(doc(db, "users", currentUser.uid), {
            displayName,
            email: currentUser.email,
            introduction: introduction,
            userType: userType,
            iconUrl: downloadURL, // Add this line
          });
          logEvent(analytics, "fetch_data", { screen_name: "UserRegistrationPage" }); 
          navigate("/event-list");
        }
      );
    } else {
      await setDoc(doc(db, "users", currentUser.uid), {
        displayName,
        email: currentUser.email,
        introduction: introduction,
        userType: userType,
      });
      logEvent(analytics, "fetch_data", { screen_name: "UserRegistrationPage" });
      navigate("/event-list");
    } 
  }catch (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <Container>
      <h1 className="text-center mt-4">ユーザ登録</h1>
      <Form onSubmit={handleSubmit}>
      <Form.Group>
          <Form.Label>メールアドレス</Form.Label>
          <Form.Control
            type="email"
            value={email}
            readOnly // メールアドレスが変更できないようにする
            style={{ backgroundColor: "#e9ecef" }} // 背景色をグレーにする
          />
        </Form.Group>
        <Form.Group controlId="userType">
        <Form.Label>ユーザータイプ</Form.Label>
          <Form.Select
            required
            value={userType}
            onChange={(e) => setUserType(e.target.value)}
            >
            <option value="">選択してください</option>
            <option value="個人">個人</option>
            <option value="飲食店">飲食店</option>
            <option value="寄付先">寄付先</option>
          </Form.Select>
        </Form.Group>

        <Form.Group>
          <Form.Label>名前</Form.Label>
          <Form.Control
            type="text"
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="introduction">
        <Form.Label>自己紹介</Form.Label>
        <Form.Control
            as="textarea"
            rows={3}
            value={introduction}
            onChange={(e) => setIntroduction(e.target.value)}
            />
        </Form.Group>

        <Form.Group>
            <Form.Label>アイコン</Form.Label>
            <Form.Control type="file" onChange={handleIconChange} />
        </Form.Group>

        {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
        <Button type="submit" className="w-100 mt-3">
          登録
        </Button>
      </Form>
    </Container>
  );
}

export default UserRegistrationPage;
