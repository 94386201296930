import React from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { useAuth } from "./AuthProvider"; // 追加
import { LinkContainer } from "react-router-bootstrap";
import { getAuth, signOut, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useLocation } from "react-router-dom";

const analytics = getAnalytics();

const Navigation = () => {
  const { user } = useAuth();

  const location = useLocation();
  const isDashboardPage = location.pathname === "/dashboard";

  const handleLogout = async () => {
    const auth = getAuth();
    try {
      await signOut(auth);
    } catch (error) {
      console.error("ログアウトに失敗しました:", error);
    }
  };

  const signInWithGoogle = async () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      logEvent(analytics, "login", { method: 'Google' }, { screen_name: "Navbar" });
    } catch (error) {
      console.error("Google認証に失敗しました:", error);
    }
  };

  if (isDashboardPage) {
    return null;
  }

  return (
    <Navbar bg="light" expand="lg">
      <LinkContainer to="/">
        <Navbar.Brand>GOURMANDY</Navbar.Brand>
      </LinkContainer>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          {user ? (
            <>
              <LinkContainer to="/event-list">
                <Nav.Link>イベント一覧</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/create-event">
                <Nav.Link>イベントを作成</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/restaurant-list">
                <Nav.Link>飲食店一覧</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/nonprofits">
                <Nav.Link>寄付先一覧</Nav.Link>
              </LinkContainer>
{/*
              <LinkContainer to="/how-it-works">
                <Nav.Link>使い方</Nav.Link>
              </LinkContainer>
*/}
              <NavDropdown title={user.displayName || user.email} id="basic-nav-dropdown">
  <LinkContainer to={`/user-profile/${user.uid}`}>
    <NavDropdown.Item>プロフィール</NavDropdown.Item>
  </LinkContainer>
  <NavDropdown.Divider />
  <NavDropdown.Item onClick={handleLogout}>ログアウト</NavDropdown.Item>
</NavDropdown>

            </>
          ) : (
            <Nav.Link onClick={signInWithGoogle}>ログイン</Nav.Link>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Navigation;
