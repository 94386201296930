import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db,getEvents } from "../../firebaseConfig";
import './PageTransition.css';
import './EventList.css';
import { getAnalytics, logEvent } from "firebase/analytics";
import { Helmet } from "react-helmet";

const analytics = getAnalytics();

function logScreenView(screenName) {
  logEvent(analytics, "screen_view", {
    screen_name: screenName,
  });
}


function EventListPage() {
  const [events, setEvents] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
//  const [setIsLoaded] = useState(false);  
  const [currentUser] = useState(null);

  useEffect(() => {
    document.title = 'EventListPage';
    logScreenView('EventListPage');
  }, []);

useEffect(() => {
  const fetchData = async () => {
    const q = query(collection(db, "events"), where("dateTime", ">=", new Date().toISOString()));
    const querySnapshot = await getDocs(q);
    const eventsData = [];
    querySnapshot.forEach((doc) => {
      eventsData.push({ ...doc.data(), id: doc.id });
    });
    setEvents(eventsData);

    // Firestore からデータを読み込んだ後、カスタムイベントを記録
    logEvent(analytics, "fetch_events", { screen_name: "EventListPage" });
  };
  fetchData();
}, []);

useEffect(() => {
  if (currentUser && currentUser.userType === "飲食店") {
    const fetchEventsForRestaurant = async () => {
      const eventsData = await getEvents();
      setEvents(eventsData);
    };
    fetchEventsForRestaurant();
  }
}, [currentUser]);

  useEffect(() => {
    const fetchData = async () => {
      const q = query(collection(db, "events"), where("dateTime", ">=", new Date().toISOString()));
      const querySnapshot = await getDocs(q);
      const eventsData = [];
      querySnapshot.forEach((doc) => {
        eventsData.push({ ...doc.data(), id: doc.id });
      });
      setEvents(eventsData);
  
      // すべての画像が読み込まれるまで待機
      const imagePromises = eventsData.map((event) =>
        new Promise((resolve) => {
          const img = new Image();
          img.src = event.imageUrl;
          img.onload = resolve;
        })
      );
      await Promise.all(imagePromises);
  
      setIsLoaded(true);

      // Firestore からデータを読み込んだ後、カスタムイベントを記録
      logEvent(analytics, "fetch_data", { screen_name: "EventListPage" });
    };
    fetchData();
  }, []);

  return (
    <div className="event-list-page">
      <Helmet>
        <title>イベント一覧 | グルマンディファンドレイジング</title>
        <meta name="description" content="gourmandyのファンドレイジングイベント一覧です。地域の飲食店を応援するイベントを探してみましょう。"/>
      </Helmet>
      <Container>
        <h1 className="text-center mt-4">イベント一覧 | グルマンディ</h1>
        <Row className="mt-5">
          
          {events.map((event) => (
            <Col key={event.id} xs={12} sm={6} md={4} className="mb-4">
              <Link to={`/event/${event.id || ''}`} className="card-link">
              <Card className="mt-3">
              <div className="card-img-wrapper">
                <Card.Img className="card-img-top" variant="top" src={event.imageUrl} />
              </div>
              <Card.Body>
                <Card.Title className="card-title">{event.eventName}</Card.Title>
                <Card.Text>日時：{new Date(event.dateTime).toLocaleString()}</Card.Text>
                <Card.Text>場所：{event.location}</Card.Text>
                <Card.Text>寄付先：{event.organization}</Card.Text>
                <Card.Text>募集人数：{event.capacity}</Card.Text>
              </Card.Body>
              </Card>
              </Link>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}

export default EventListPage;
