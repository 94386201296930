import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Container, Row, Col, Card, Button, ButtonGroup,Carousel } from "react-bootstrap";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { getAnalytics, logEvent } from "firebase/analytics";

const analytics = getAnalytics();

function logScreenView(screenName) {
  logEvent(analytics, "screen_view", {
    screen_name: screenName,
  });
}

function RestaurantDetailPage() {
  const storage = getStorage();

  const { id } = useParams();
  const [restaurant, setRestaurant] = useState(null);
  const navigate = useNavigate();
  const locationState = useLocation() || { state: {} };
  const fromCreateEventPage = locationState?.state?.fromCreateEventPage || false;
  const [showSelectButton, setShowSelectButton] = useState(fromCreateEventPage);


const handleBackToList = () => {
  navigate('/restaurant-list', {
    state: {
      ...locationState.state,
      fromCreateEventPage: showSelectButton,
      currentFormData: locationState?.currentFormData || {},
    },
  });
};

const getDownloadUrlFromGsPath = async (gsPath) => {
  const imageRef = ref(storage, gsPath);
  const url = await getDownloadURL(imageRef);
  return url;
};

useEffect(() => {
  document.title = 'RestaurantDetailPage';
  logScreenView('RestaurantDetailPage');
}, []);


  useEffect(() => {
    setShowSelectButton(
      locationState.state?.fromCreateEventPage || false);      

      const fetchRestaurant = async () => {
        const docRef = doc(db, "restaurants", id);
        const docSnap = await getDoc(docRef);

        logEvent(analytics, "fetch_data", { screen_name: "RestaurantDetailPage" });


        if (docSnap.exists()) {
          const data = docSnap.data();
          const imageUrls = await Promise.all(data.images.map(getDownloadUrlFromGsPath));
          setRestaurant({
            ...data,
            images: imageUrls,
          });
        } else {
          console.log("No such document!");
        }
      };
              
    fetchRestaurant();
  }, [id, locationState]);
    
  //const fromCreateEventPage = locationState?.fromCreateEventPage;


  const handleSelect = () => {
    localStorage.setItem("selectedRestaurantId", id);
    navigate("/create-event", {
      replace: true,
      state: {
        ...locationState.state,
        selectedRestaurant: restaurant,
        currentFormData: locationState?.currentFormData || null,
      },
    });
  };
            

  return (
    <Container>
      {restaurant ? (
        <>
          <h1 className="text-center mt-4 mb-4">{restaurant.name}</h1>
          <Row>
            <Col sm={12} md={7}>
              {restaurant.images && restaurant.images.length > 0 ? (
                <Carousel>
                  {restaurant.images.map((image, index) => (
                    <Carousel.Item key={index}>
                      <img
                        className="d-block w-100"
                        src={image}
                        alt={`Slide ${index + 1}`}
                      />
                    </Carousel.Item>
                  ))}
                </Carousel>
              ) : (
                <Card.Img src={restaurant.image} alt={restaurant.name} />
              )}
            </Col>
            <Col sm={12} md={5}>
              <p>住所: {restaurant.address}</p>
              <p>電話番号: {restaurant.phone}</p>
              <p>営業時間: {restaurant.hours}</p>
              <div className="d-flex">
                <ButtonGroup className="mt-2">
                  {showSelectButton && (
                    <Button onClick={handleSelect} className="custom-button-select">
                      選択
                    </Button>
                  )}
                </ButtonGroup>
                <Button onClick={handleBackToList} className="btn custom-button-no mt-2 ml-2">
                  飲食店一覧に戻る
                </Button>
              </div>
            </Col>
          </Row>
        </>
      ) : (
        <p>Loading...</p>
      )}
    </Container>
  );
}

export default RestaurantDetailPage;