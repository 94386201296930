import React, { useState } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

function PasswordPrompt() {
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    const validatePassword = httpsCallable(getFunctions(), 'validatePassword');
    validatePassword({ password })
      .then(({ data: { token } }) => {
        return signInWithCustomToken(getAuth(), token);
      })
      .then(() => {
        // The user is now authenticated!
        // Navigate to a new route
        navigate('/top');
      })
      .catch((error) => {
        // Handle errors
        console.error(error);
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="password"
        value={password}
        onChange={e => setPassword(e.target.value)}
        placeholder="Enter password"
      />
      <button type="submit">Submit</button>
    </form>
  );
}

export default PasswordPrompt;
